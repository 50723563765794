let swipeCount = 0,
    interval = 8000;
autoSwipeTabs();
let setIntervalSwipe = setInterval(function () {
    if (window.innerWidth > 900) {
        autoSwipeTabs();
    }
}, interval);

function autoSwipeTabs() {
    let swipeEl = $(".js-side-tabs .js-tab-item"),
        swipeCont = $(".js-side-tabs .js-side-content-img");
    if (swipeCount < $(swipeEl).length) {
        $(swipeEl).removeClass("active");
        $('.js-side-tabs .js-tab-timeline').css('width', '0');
        $('.js-side-tabs .side-tab__description').slideUp();
        if (window.innerWidth > 900) {
            $(swipeEl[swipeCount]).addClass("active").find('.js-tab-timeline').css('width', '100%');
        } else {
            $(swipeEl[swipeCount]).addClass("active");
        }
        $(swipeCont).attr('src', $(swipeEl[swipeCount]).data('src'));
        $(swipeEl[swipeCount]).find('.side-tab__description').slideDown();
        swipeCount++;
    } else {
        swipeCount = 0;
    }
}

$(document).on('click', '.js-tab-item:not(.active)', function () {
    $(".js-tab-item").removeClass("active");
    $('.js-side-tabs .js-tab-timeline').css('width', '0');
    $(this).addClass("active");
    if (window.innerWidth > 900) {
        $('.js-side-tabs .side-tab__description').slideUp();
        $(this).find('.side-tab__description').slideDown();
    }
    $(".js-side-tabs .js-side-content-img").attr('src', $(this).data('src'))
    clearInterval(setIntervalSwipe);
})

$(window).resize(function () {
    if (window.innerWidth > 900) {
        $('.js-tab-item.active .side-tab__description').show();
    }
    setIntervalSwipe()
})

window.onload = function () {
    var scr = $(".scroll");
    scr.mousedown(function () {
        var startX = this.scrollLeft + event.pageX;
        var startY = this.scrollTop + event.pageY;
        scr.mousemove(function () {
            this.scrollLeft = startX - event.pageX;
            this.scrollTop = startY - event.pageY;
            return false;
        });
    });
    $(window).mouseup(function () {
        scr.off("mousemove");
    });
}


if (window.innerWidth < 900) {
    function swipe() {
        $('.js-tabs-nav .swipe').fadeIn().addClass('active');
        $('.js-tabs-nav').animate({
            scrollLeft: 100
        }, 700)
        setTimeout(function () {
            $('.js-tabs-nav').animate({
                scrollLeft: -40
            }, 700);
            setTimeout(function () {
                $('.js-tabs-nav .swipe').removeClass('active').fadeOut();
            }, 700)
        }, 1000)
    }


    let postion = $('.js-side-tabs').offset().top,
        height = $('.js-side-tabs').height();
    let count = 0;
    $(document).on('scroll', function () {
        let scroll = $(document).scrollTop();
        if (scroll > postion - 100 && scroll < (postion + height) && count < 1) {
            swipe();
            count++
        }
    })
}